.Produktspezifikation--input-description--container {
  width: 350px;
  margin-right: 10px;
}

.Produktspezifikation--row {
  margin-top: 20px;
  align-items: flex-end;
  display: flex;
}

.Produktspezifikation--img {
  margin-right: 10px;
  width: 150px;
}

.Produktspezifikation--input {
  width: 250px;
}